import * as Types from '../types/generated';

import * as Operations from '../generated';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient } from '../../../libs/apolloClient';




export async function getServerPageCategories
    (options: Omit<Apollo.QueryOptions<Types.CategoriesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoriesQuery>({ ...options, query: Operations.CategoriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCategoriesComp = React.FC<{data?: Types.CategoriesQuery, error?: Apollo.ApolloError}>;
export const ssrCategories = {
      getServerPage: getServerPageCategories,
      
      
    }
export async function getServerPageActiveCategory
    (options: Omit<Apollo.QueryOptions<Types.ActiveCategoryQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ActiveCategoryQuery>({ ...options, query: Operations.ActiveCategoryDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageActiveCategoryComp = React.FC<{data?: Types.ActiveCategoryQuery, error?: Apollo.ApolloError}>;
export const ssrActiveCategory = {
      getServerPage: getServerPageActiveCategory,
      
      
    }
export async function getServerPageCountries
    (options: Omit<Apollo.QueryOptions<Types.CountriesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CountriesQuery>({ ...options, query: Operations.CountriesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCountriesComp = React.FC<{data?: Types.CountriesQuery, error?: Apollo.ApolloError}>;
export const ssrCountries = {
      getServerPage: getServerPageCountries,
      
      
    }
export async function getServerPageCollections
    (options: Omit<Apollo.QueryOptions<Types.CollectionsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CollectionsQuery>({ ...options, query: Operations.CollectionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCollectionsComp = React.FC<{data?: Types.CollectionsQuery, error?: Apollo.ApolloError}>;
export const ssrCollections = {
      getServerPage: getServerPageCollections,
      
      
    }
export async function getServerPageCollection
    (options: Omit<Apollo.QueryOptions<Types.CollectionQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CollectionQuery>({ ...options, query: Operations.CollectionDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCollectionComp = React.FC<{data?: Types.CollectionQuery, error?: Apollo.ApolloError}>;
export const ssrCollection = {
      getServerPage: getServerPageCollection,
      
      
    }
export async function getServerPageCollectionsByLabel
    (options: Omit<Apollo.QueryOptions<Types.CollectionsByLabelQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CollectionsByLabelQuery>({ ...options, query: Operations.CollectionsByLabelDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCollectionsByLabelComp = React.FC<{data?: Types.CollectionsByLabelQuery, error?: Apollo.ApolloError}>;
export const ssrCollectionsByLabel = {
      getServerPage: getServerPageCollectionsByLabel,
      
      
    }
export async function getServerPageCollectionsByManufacturer
    (options: Omit<Apollo.QueryOptions<Types.CollectionsByManufacturerQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CollectionsByManufacturerQuery>({ ...options, query: Operations.CollectionsByManufacturerDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCollectionsByManufacturerComp = React.FC<{data?: Types.CollectionsByManufacturerQuery, error?: Apollo.ApolloError}>;
export const ssrCollectionsByManufacturer = {
      getServerPage: getServerPageCollectionsByManufacturer,
      
      
    }
export async function getServerPageNewCollections
    (options: Omit<Apollo.QueryOptions<Types.NewCollectionsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.NewCollectionsQuery>({ ...options, query: Operations.NewCollectionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageNewCollectionsComp = React.FC<{data?: Types.NewCollectionsQuery, error?: Apollo.ApolloError}>;
export const ssrNewCollections = {
      getServerPage: getServerPageNewCollections,
      
      
    }
export async function getServerPageSliderCollections
    (options: Omit<Apollo.QueryOptions<Types.SliderCollectionsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SliderCollectionsQuery>({ ...options, query: Operations.SliderCollectionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageSliderCollectionsComp = React.FC<{data?: Types.SliderCollectionsQuery, error?: Apollo.ApolloError}>;
export const ssrSliderCollections = {
      getServerPage: getServerPageSliderCollections,
      
      
    }
export async function getServerPageArticles
    (options: Omit<Apollo.QueryOptions<Types.ArticlesQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ArticlesQuery>({ ...options, query: Operations.ArticlesDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageArticlesComp = React.FC<{data?: Types.ArticlesQuery, error?: Apollo.ApolloError}>;
export const ssrArticles = {
      getServerPage: getServerPageArticles,
      
      
    }
export async function getServerPageArticle
    (options: Omit<Apollo.QueryOptions<Types.ArticleQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ArticleQuery>({ ...options, query: Operations.ArticleDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageArticleComp = React.FC<{data?: Types.ArticleQuery, error?: Apollo.ApolloError}>;
export const ssrArticle = {
      getServerPage: getServerPageArticle,
      
      
    }
export async function getServerPageLinks
    (options: Omit<Apollo.QueryOptions<Types.LinksQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.LinksQuery>({ ...options, query: Operations.LinksDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageLinksComp = React.FC<{data?: Types.LinksQuery, error?: Apollo.ApolloError}>;
export const ssrLinks = {
      getServerPage: getServerPageLinks,
      
      
    }
export async function getServerPageCategoryFilters
    (options: Omit<Apollo.QueryOptions<Types.CategoryFiltersQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoryFiltersQuery>({ ...options, query: Operations.CategoryFiltersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCategoryFiltersComp = React.FC<{data?: Types.CategoryFiltersQuery, error?: Apollo.ApolloError}>;
export const ssrCategoryFilters = {
      getServerPage: getServerPageCategoryFilters,
      
      
    }
export async function getServerPageFiltersByManufacturer
    (options: Omit<Apollo.QueryOptions<Types.FiltersByManufacturerQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.FiltersByManufacturerQuery>({ ...options, query: Operations.FiltersByManufacturerDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageFiltersByManufacturerComp = React.FC<{data?: Types.FiltersByManufacturerQuery, error?: Apollo.ApolloError}>;
export const ssrFiltersByManufacturer = {
      getServerPage: getServerPageFiltersByManufacturer,
      
      
    }
export async function getServerPageManufacturersGroups
    (options: Omit<Apollo.QueryOptions<Types.ManufacturersGroupsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ManufacturersGroupsQuery>({ ...options, query: Operations.ManufacturersGroupsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageManufacturersGroupsComp = React.FC<{data?: Types.ManufacturersGroupsQuery, error?: Apollo.ApolloError}>;
export const ssrManufacturersGroups = {
      getServerPage: getServerPageManufacturersGroups,
      
      
    }
export async function getServerPageManufacturer
    (options: Omit<Apollo.QueryOptions<Types.ManufacturerQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ManufacturerQuery>({ ...options, query: Operations.ManufacturerDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageManufacturerComp = React.FC<{data?: Types.ManufacturerQuery, error?: Apollo.ApolloError}>;
export const ssrManufacturer = {
      getServerPage: getServerPageManufacturer,
      
      
    }
export async function getServerPageManufacturers
    (options: Omit<Apollo.QueryOptions<Types.ManufacturersQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ManufacturersQuery>({ ...options, query: Operations.ManufacturersDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageManufacturersComp = React.FC<{data?: Types.ManufacturersQuery, error?: Apollo.ApolloError}>;
export const ssrManufacturers = {
      getServerPage: getServerPageManufacturers,
      
      
    }
export async function getServerPageOrder
    (options: Omit<Apollo.QueryOptions<Types.OrderQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.OrderQuery>({ ...options, query: Operations.OrderDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageOrderComp = React.FC<{data?: Types.OrderQuery, error?: Apollo.ApolloError}>;
export const ssrOrder = {
      getServerPage: getServerPageOrder,
      
      
    }
export async function getServerPageSeo
    (options: Omit<Apollo.QueryOptions<Types.PageSeoQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PageSeoQuery>({ ...options, query: Operations.PageSeoDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageSeoComp = React.FC<{data?: Types.PageSeoQuery, error?: Apollo.ApolloError}>;
export const ssrSeo = {
      getServerPage: getServerPageSeo,
      
      
    }
export async function getServerPageItems
    (options: Omit<Apollo.QueryOptions<Types.ItemsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ItemsQuery>({ ...options, query: Operations.ItemsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageItemsComp = React.FC<{data?: Types.ItemsQuery, error?: Apollo.ApolloError}>;
export const ssrItems = {
      getServerPage: getServerPageItems,
      
      
    }
export async function getServerPageItem
    (options: Omit<Apollo.QueryOptions<Types.ItemQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ItemQuery>({ ...options, query: Operations.ItemDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageItemComp = React.FC<{data?: Types.ItemQuery, error?: Apollo.ApolloError}>;
export const ssrItem = {
      getServerPage: getServerPageItem,
      
      
    }
export async function getServerPageCategoryItems
    (options: Omit<Apollo.QueryOptions<Types.CategoryItemsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.CategoryItemsQuery>({ ...options, query: Operations.CategoryItemsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageCategoryItemsComp = React.FC<{data?: Types.CategoryItemsQuery, error?: Apollo.ApolloError}>;
export const ssrCategoryItems = {
      getServerPage: getServerPageCategoryItems,
      
      
    }
export async function getServerPageItemsByCollection
    (options: Omit<Apollo.QueryOptions<Types.ItemsByCollectionQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ItemsByCollectionQuery>({ ...options, query: Operations.ItemsByCollectionDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageItemsByCollectionComp = React.FC<{data?: Types.ItemsByCollectionQuery, error?: Apollo.ApolloError}>;
export const ssrItemsByCollection = {
      getServerPage: getServerPageItemsByCollection,
      
      
    }
export async function getServerPageItemsByManufacturer
    (options: Omit<Apollo.QueryOptions<Types.ItemsByManufacturerQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ItemsByManufacturerQuery>({ ...options, query: Operations.ItemsByManufacturerDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageItemsByManufacturerComp = React.FC<{data?: Types.ItemsByManufacturerQuery, error?: Apollo.ApolloError}>;
export const ssrItemsByManufacturer = {
      getServerPage: getServerPageItemsByManufacturer,
      
      
    }
export async function getServerPageItemQuickSearch
    (options: Omit<Apollo.QueryOptions<Types.ItemQuickSearchQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ItemQuickSearchQuery>({ ...options, query: Operations.ItemQuickSearchDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageItemQuickSearchComp = React.FC<{data?: Types.ItemQuickSearchQuery, error?: Apollo.ApolloError}>;
export const ssrItemQuickSearch = {
      getServerPage: getServerPageItemQuickSearch,
      
      
    }
export async function getServerPageItemSuggestions
    (options: Omit<Apollo.QueryOptions<Types.ItemSuggestionsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.ItemSuggestionsQuery>({ ...options, query: Operations.ItemSuggestionsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageItemSuggestionsComp = React.FC<{data?: Types.ItemSuggestionsQuery, error?: Apollo.ApolloError}>;
export const ssrItemSuggestions = {
      getServerPage: getServerPageItemSuggestions,
      
      
    }
export async function getServerPage
    (options: Omit<Apollo.QueryOptions<Types.PageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PageQuery>({ ...options, query: Operations.PageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageComp = React.FC<{data?: Types.PageQuery, error?: Apollo.ApolloError}>;
export const ssr = {
      getServerPage: getServerPage,
      
      
    }
export async function getServerPagePayment
    (options: Omit<Apollo.QueryOptions<Types.PaymentQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.PaymentQuery>({ ...options, query: Operations.PaymentDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PagePaymentComp = React.FC<{data?: Types.PaymentQuery, error?: Apollo.ApolloError}>;
export const ssrPayment = {
      getServerPage: getServerPagePayment,
      
      
    }
export async function getServerPageSiteMessage
    (options: Omit<Apollo.QueryOptions<Types.SiteMessageQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SiteMessageQuery>({ ...options, query: Operations.SiteMessageDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageSiteMessageComp = React.FC<{data?: Types.SiteMessageQuery, error?: Apollo.ApolloError}>;
export const ssrSiteMessage = {
      getServerPage: getServerPageSiteMessage,
      
      
    }
export async function getServerPageRedirects
    (options: Omit<Apollo.QueryOptions<Types.RedirectsQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.RedirectsQuery>({ ...options, query: Operations.RedirectsDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageRedirectsComp = React.FC<{data?: Types.RedirectsQuery, error?: Apollo.ApolloError}>;
export const ssrRedirects = {
      getServerPage: getServerPageRedirects,
      
      
    }
export async function getServerPageSuggestion
    (options: Omit<Apollo.QueryOptions<Types.SuggestionQueryVariables>, 'query'>, ctx?: any ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<Types.SuggestionQuery>({ ...options, query: Operations.SuggestionDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export type PageSuggestionComp = React.FC<{data?: Types.SuggestionQuery, error?: Apollo.ApolloError}>;
export const ssrSuggestion = {
      getServerPage: getServerPageSuggestion,
      
      
    }